import React from 'react'
import DropdownItemsWrapper from '../../dropdown-items-wrapper'
import SmartLinkBasedOnUserType from '../../smart-link-based-on-user-type'
import { Typography } from '@mui/material'
import LabelWithBannerNew from '../../../label-with-banner-new'
import propTypes from 'prop-types'

function ProductsList({ onAfterClick }) {
  const handleClick = ctaToTrack => {
    if (onAfterClick) onAfterClick(ctaToTrack)
  }

  return (
    <DropdownItemsWrapper>
      <SmartLinkBasedOnUserType
        defaultPublicPagePath="/personalized-subscription/"
        onAfterClick={() => handleClick('personalized-subscription')}
      >
        <Typography variant="body2">3-Step Skincare System</Typography>
      </SmartLinkBasedOnUserType>

      <SmartLinkBasedOnUserType
        defaultPublicPagePath="/personalized-face-cleanser/"
        onAfterClick={() => handleClick('personalized-face-cleanser')}
      >
        <Typography variant="body2">Personalized Cleanser</Typography>
      </SmartLinkBasedOnUserType>

      <SmartLinkBasedOnUserType
        defaultPublicPagePath="/personalized-day-moisturizer/"
        onAfterClick={() => handleClick('personalized-day-moisturizer')}
      >
        <Typography variant="body2">Personalized Day Moisturizer</Typography>
      </SmartLinkBasedOnUserType>

      <SmartLinkBasedOnUserType
        defaultPublicPagePath="/personalized-night-cream/"
        onAfterClick={() => handleClick('personalized-night-cream')}
      >
        <Typography variant="body2">Personalized Night Cream</Typography>
      </SmartLinkBasedOnUserType>

      <SmartLinkBasedOnUserType
        defaultPublicPagePath="/personalized-eye-cream-duo/"
        onAfterClick={() => handleClick('personalized-eye-cream-duo')}
      >
        <Typography variant="body2">Eye Cream Duo</Typography>
      </SmartLinkBasedOnUserType>

      <SmartLinkBasedOnUserType
        defaultPublicPagePath="/personalized-day-eye-cream"
        onAfterClick={() => handleClick('personalized-day-eye-cream')}
      >
        <Typography variant="body2">Day Eye Cream</Typography>
      </SmartLinkBasedOnUserType>

      <SmartLinkBasedOnUserType
        defaultPublicPagePath="/personalized-night-eye-cream"
        onAfterClick={() => handleClick('personalized-night-eye-cream')}
      >
        <Typography variant="body2">Night Eye Cream</Typography>
      </SmartLinkBasedOnUserType>

      <SmartLinkBasedOnUserType
        defaultPublicPagePath="/personalized-serum"
        onAfterClick={() => handleClick('personalized-serum')}
      >
        <LabelWithBannerNew>
          <Typography variant="body2">All-In-One Serum</Typography>
        </LabelWithBannerNew>
      </SmartLinkBasedOnUserType>
    </DropdownItemsWrapper>
  )
}
ProductsList.propTypes = {
  onAfterClick: propTypes.func
}

export default ProductsList
