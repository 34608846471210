import React, { useEffect } from 'react'
import { Box, Drawer, Button } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import useAuth from 'hooks/use-auth'
import useHasSubscription from '../../../../hooks/useHasSubscription'
import { closeMobileMenu } from '../../../../actions/app.actions'
import { useHistory } from 'react-router-dom'
import { trackEvent } from '../../../../utils/analytics'
import FooterButtons from './footer-buttons'
import useMobileMenu from '../../../../hooks/useMobileMenu'
import ItemsList from './items-list'
import useWidth from '../../../../hooks/useWidth'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createProvenTheme } from '../../../../styles/theme-proven'
import { LOGO_ONE_LINE, LOGO_SEPHORA } from '../../../../constants/logos'
import ProvenLogo from '../../proven-logo'
import ProvenIcon from '../../proven-icon'
import { ThemeProvider } from '@mui/material/styles'
import { appEditionSelector } from '../../../../utils/selectors'
import { APP_EDITION_SEPHORA } from '../../../../constants/constants'

const theme = createProvenTheme()
const { spacing } = theme

function ProvenMobileMenu() {
  const width = useWidth()
  const dispatch = useDispatch()
  const appEdition = useSelector(appEditionSelector)
  const { isMobileMenuOpen } = useMobileMenu()
  const { isAuthenticated } = useAuth()
  const hasSubscription = useHasSubscription()
  const dashboard = isAuthenticated && hasSubscription ? 'RUD' : undefined
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const history = useHistory()

  const goToHomePage = () => {
    trackEvent('header_cta', { CTA: 'Logo' })
    handleCloseMenu()
    history.push('/')
  }

  const handleCloseMenu = () => {
    dispatch(closeMobileMenu())
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (isMobileMenuOpen) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'unset'
      }
    }
    function handleResize() {
      if (width !== 'xs' && width !== 'sm' && isMobileMenuOpen) {
        handleCloseMenu()
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileMenuOpen])

  const handlePrimaryMenuItemClick = cta => {
    if (cta)
      trackEvent('header_cta', {
        CTA: cta,
        dashboard: dashboard,
        location: 'left_mobie_menu'
      })
    handleCloseMenu()
  }

  if (isMdUp) return null
  return (
    <ThemeProvider theme={theme}>
      <Drawer
        disableScrollLock={true}
        PaperProps={{
          sx: {
            width: '74.55vw',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: `${spacing(8.25)} ${spacing(8.25)} auto ${spacing(20)}`,
            gridTemplateAreas: `"close . ."
                                "logo logo logo"
                                "items items items"
                                "buttons buttons buttons"`,

            columnGap: 2,
            display: 'grid'
          }
        }}
        anchor="left"
        open={isMobileMenuOpen}
        onClose={handleCloseMenu}
      >
        <Box
          sx={{
            gridArea: 'close',
            display: 'grid',
            justifyContent: 'start',
            alignItems: 'start',
            px: 2,
            pt: 2
          }}
        >
          <Button
            sx={{ px: 0, py: 0, minWidth: 'unset', alignItems: 'flex-start' }}
            variant="icon"
            onClick={handleCloseMenu}
          >
            <ProvenIcon
              size="small"
              name="cross-close"
              type="system"
              color={theme.palette.gray.elysian}
            />
          </Button>
        </Box>
        <Box sx={{ gridArea: 'logo', px: 2 }}>
          <ProvenLogo
            variant={appEdition === APP_EDITION_SEPHORA ? LOGO_SEPHORA : LOGO_ONE_LINE}
            onClick={goToHomePage}
          />
        </Box>
        <ItemsList
          sx={{ gridArea: 'items', px: 2, overflowY: 'auto', overflowX: 'hidden' }}
          handlePrimaryMenuItemClick={handlePrimaryMenuItemClick}
        />
        <Box sx={{ gridArea: 'buttons', px: 2, display: 'grid', alignItems: 'end' }}>
          <FooterButtons onAfterClick={handlePrimaryMenuItemClick} />
        </Box>
      </Drawer>
    </ThemeProvider>
  )
}

export default ProvenMobileMenu
