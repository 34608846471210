import React, { useState } from 'react'
import { learnItems, mobileMenuDashboardItems } from '../../menu-items'
import SkinQuizMenu from '../../skin-quiz-menu'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useAuth from 'hooks/use-auth'
import { capitalize } from 'lodash'
import useUserTypeAccess from '../../../../../hooks/useUserTypeAccess'
import {
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography
} from '@mui/material'
import ProvenIcon from '../../../proven-icon'
import { createProvenTheme } from '../../../../../styles/theme-proven'
import DropdownItemsWrapper from '../../dropdown-items-wrapper'
import ProductsList from '../../menu-items/products-list'
import { Link } from 'react-router-dom'
const LEARN_ITEM_LABEL = 'Learn'
const PRODUCTS_ITEM_LABEL = 'Products'
const SKIN_QUIZ_ITEM_LABEL = 'Skin Quiz'

const theme = createProvenTheme()
const { spacing } = theme

function ItemsList({ handlePrimaryMenuItemClick, sx }) {
  const [labelOfOpenItem, setLabelOfOpenItem] = useState()
  const firstName = useSelector(state => state.account.info.firstName)
  const { hasAccessTo } = useUserTypeAccess()
  const hasARudLinkWithAllowedAccess = item =>
    item.type !== 'divider' && item.id !== 'logout' && item.link && hasAccessTo(item.link)
  const hasAccessToDashboard = () => mobileMenuDashboardItems.some(hasARudLinkWithAllowedAccess)
  const filteredDashboardItems = mobileMenuDashboardItems.filter(
    item => item.type === 'divider' || hasARudLinkWithAllowedAccess(item)
  )

  const { isAuthenticated } = useAuth()
  const isDashboardItemShown = isAuthenticated && hasAccessToDashboard()
  const dashboardItemLabel = isDashboardItemShown ? `${capitalize(firstName)}'s Dashboard` : ''

  const isDashboardMenuExpanded = labelOfOpenItem === dashboardItemLabel
  const isSkinQuizExpanded = labelOfOpenItem === SKIN_QUIZ_ITEM_LABEL
  const isProductsExpanded = labelOfOpenItem === PRODUCTS_ITEM_LABEL
  const isLearnExpanded = labelOfOpenItem === LEARN_ITEM_LABEL

  const handleToggleOpen = label => {
    setLabelOfOpenItem(labelThatWasOpen => (label !== labelThatWasOpen ? label : undefined))
  }

  const accordionSx = {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
      backgroundColor: 'transparent !important'
    }
  }
  const summarySx = {
    height: spacing(7.5),
    px: 0
  }
  const dividerSx = {
    backgroundColor: theme.palette.gray.middleGray,
    opacity: 0.2,
    position: 'relative',
    left: spacing(-2),
    width: `calc(100% + ${spacing(4)})`
  }

  return (
    <Stack sx={sx}>
      {isDashboardItemShown && (
        <>
          <Divider textAlign="left" sx={dividerSx} />
          <Accordion
            sx={accordionSx}
            disableGutters
            expanded={isDashboardMenuExpanded}
            onChange={() => handleToggleOpen(dashboardItemLabel)}
          >
            <AccordionSummary
              sx={summarySx}
              expandIcon={
                <ProvenIcon
                  type="system"
                  name="arrow-down"
                  size="0.5rem"
                  color={
                    isDashboardMenuExpanded
                      ? theme.palette.primary.main
                      : theme.palette.gray.elysian
                  }
                />
              }
            >
              <Typography
                variant="button"
                color={
                  isDashboardMenuExpanded ? theme.palette.primary.main : theme.palette.gray.elysian
                }
              >
                {dashboardItemLabel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, pb: 3 }}>
              <DropdownItemsWrapper>
                {filteredDashboardItems.map((item, i) => (
                  <Link
                    key={`dashboard-item-${i}`}
                    to={item.link}
                    onClick={() => handlePrimaryMenuItemClick(item.label)}
                  >
                    <Typography variant="body2" color="gray.gray">
                      {item.label}
                    </Typography>
                  </Link>
                ))}
              </DropdownItemsWrapper>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      <Divider textAlign="left" sx={dividerSx} />
      <Accordion
        sx={accordionSx}
        variant="mobileMenuItem"
        disableGutters
        expanded={isSkinQuizExpanded}
        onChange={() => handleToggleOpen(SKIN_QUIZ_ITEM_LABEL)}
      >
        <AccordionSummary
          sx={summarySx}
          expandIcon={
            <ProvenIcon
              type="system"
              name="arrow-down"
              size="0.5rem"
              color={isSkinQuizExpanded ? theme.palette.primary.main : theme.palette.gray.elysian}
            />
          }
        >
          <Typography
            variant="button"
            color={isSkinQuizExpanded ? theme.palette.primary.main : theme.palette.gray.elysian}
          >
            SKIN QUIZ
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, pb: 3, width: 'max-content' }}>
          <SkinQuizMenu small={true} />
        </AccordionDetails>
      </Accordion>
      <Divider textAlign="left" sx={dividerSx} />
      <Accordion
        sx={accordionSx}
        disableGutters
        expanded={isProductsExpanded}
        onChange={() => handleToggleOpen(PRODUCTS_ITEM_LABEL)}
      >
        <AccordionSummary
          sx={summarySx}
          expandIcon={
            <ProvenIcon
              type="system"
              name="arrow-down"
              size="0.5rem"
              color={isProductsExpanded ? theme.palette.primary.main : theme.palette.gray.elysian}
            />
          }
        >
          <Typography
            variant="button"
            color={isProductsExpanded ? theme.palette.primary.main : theme.palette.gray.elysian}
          >
            PRODUCTS
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, pb: 3 }}>
          <ProductsList onAfterClick={handlePrimaryMenuItemClick} />
        </AccordionDetails>
      </Accordion>
      <Divider textAlign="left" sx={dividerSx} />
      <Accordion
        sx={accordionSx}
        disableGutters
        expanded={isLearnExpanded}
        onChange={() => handleToggleOpen(LEARN_ITEM_LABEL)}
      >
        <AccordionSummary
          sx={summarySx}
          expandIcon={
            <ProvenIcon
              type="system"
              name="arrow-down"
              size="0.5rem"
              color={isLearnExpanded ? theme.palette.primary.main : theme.palette.gray.elysian}
            />
          }
        >
          <Typography
            variant="button"
            color={isLearnExpanded ? theme.palette.primary.main : theme.palette.gray.elysian}
          >
            LEARN
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, pb: 3 }}>
          <DropdownItemsWrapper>
            {learnItems.map((item, i) => (
              <Link
                key={`learn-item-${i}`}
                to={item.link}
                onClick={() => handlePrimaryMenuItemClick(item.label)}
              >
                <Typography>{item.label}</Typography>
              </Link>
            ))}
          </DropdownItemsWrapper>
        </AccordionDetails>
      </Accordion>
      <Divider textAlign="left" sx={dividerSx} />
      <Box
        sx={{
          py: spacing(2.25),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Link to="/reviews/" onClick={() => handlePrimaryMenuItemClick('reviews')}>
          <Typography variant="button" color={theme.palette.gray.elysian}>
            Reviews
          </Typography>
        </Link>
      </Box>
      <Divider textAlign="left" sx={dividerSx} />
    </Stack>
  )
}

ItemsList.propTypes = {
  handlePrimaryMenuItemClick: propTypes.func,
  sx: propTypes.object
}

export default ItemsList
