import React from 'react'
import { Auth } from 'aws-amplify'
import useAuth from 'hooks/use-auth'
import { toggleLoginSlide } from '../../../../../actions/checkout.actions'
import { useDispatch } from 'react-redux'
import ProvenButtonWithIntent from '../../../proven-button-with-intent'
import { signoutUser } from '../../../../../actions/auth.actions'
import { Stack, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'

function FooterButtons({ onAfterClick }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const isThankyouPage = history.location?.pathname?.includes('/account/thank-you')

  const handleOpenLogin = () => {
    dispatch(toggleLoginSlide(true))
    if (onAfterClick) onAfterClick('login')
  }
  const handleLogout = () => {
    history.push('/')
    Auth.signOut()
    if (onAfterClick) onAfterClick('logout')
  }

  const onAfterButtonWithIntentClick = () => {
    if (onAfterClick) onAfterClick('Get my formula')
  }

  const { isAuthenticated } = useAuth()
  return (
    <Stack
      sx={{
        width: 'min-content',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        gap: 2,
        pb: 4
      }}
    >
      {isAuthenticated ? (
        <Button sx={{ px: 0 }} variant="text" color="primary" onClick={handleLogout}>
          LOGOUT
        </Button>
      ) : (
        <>
          <Button
            sx={{ width: '100%' }}
            variant="outlined"
            color="primary"
            onClick={handleOpenLogin}
          >
            LOGIN
          </Button>
          <ProvenButtonWithIntent
            sx={{ width: 'max-content' }}
            variant="contained"
            color="secondary"
            text="TAKE THE SKIN QUIZ"
            data-cy="start-skin-quiz-button"
            onClick={onAfterButtonWithIntentClick}
          />
        </>
      )}
    </Stack>
  )
}

export default FooterButtons
